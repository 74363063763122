<template>
  <div class="bg-grey">
    <div class="flex-box">
      <div class="lt">图片标题</div>
      <div class="rt">
        <a-input placeholder="请输入" style="width: 400px" v-model="title"/>
      </div>
    </div>
    <uploadFile
      :isImgUpload="true"
      :multiple="true"
      @success="uploadImgSuccess"
      :fileLists="imgList"
      :maxNum="maxNum"
      @uploadLoainng="uploadLoainng"
    ></uploadFile>
    <span class="close" @click="deletePic"><a-icon type="close"/></span>
  </div>
</template>

<script>
export default {
  name: 'uploadPicsList',
  components: {
    uploadFile: () => import('@/components/upload/uploadFile'),
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    maxNum: {
      type: Number,
      default: 5,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      title: this.data.title || '',
      imgId: '',
      imgList: [],
    };
  },
  watch: {
    title: {
      handler() {
      },
    },
    data: {
      handler(value) {
        this.title = value.image_title;
        if (this?.data?.images_url) {
          this.imgList = [
            {
              uid: this?.data?.image_id || 0,
              name: this?.data?.image_title || '',
              status: 'done',
              url: `https://${this?.data?.images_url}`,
            },
          ];
        }
      },
    },
  },
  methods: {
    deletePic() {
      this.$emit('delete', this.index);
    },
    uploadImgSuccess(data, list) {
      this.imgList = list;
      this.imgId = data.file_resource_id;
    },
    saveImgList() {
      let list = this.imgList;
      let obj = {
        title: this.title,
      };
      let path = [];
      for (let i = 0; i < list.length; i++) {
        path.push(list[i].file_resource_id);
      }
      obj.path = path;
      this.$emit('saveImgList', obj);
    },
    saveSingelList(str) {
      let obj = {
        title: this.title,
      };
      obj[str] = this.imgId;
      this.$emit('saveImgList', obj);
    },
    uploadLoainng(value) {
      this.$emit('uploadLoainng', value);
    },
  },
  mounted() {
    this.title = this?.data?.image_title;
    if (this?.data?.images_url) {
      this.imgList = [
        {
          uid: this?.data?.image_id || 0,
          name: this?.data?.image_title || '',
          status: 'done',
          url: `https://${this?.data?.images_url}`,
        },
      ];
    }
  },
};
</script>

<style lang="less" scoped>
.bg-grey {
  background-color: rgb(242, 242, 242);
  padding: 15px;
  position: relative;
  margin-top: 10px;

  .flex-box {
    display: flex;
    padding-bottom: 10px;

    .rt {
      flex: 1;
    }

    .lt {
      padding-right: 10px;
    }
  }

  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 15px;
    height: 15px;
    display: block;
  }
}
</style>
